b<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row"><div class="col-lg-12"></div></div>
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area
          class="mt-2"
          v-model:value="area"
          v-model:provinsi="provinsi"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:region="region"
          v-model:provinsi="provinsi"
          v-model:area="area"
          v-can:hide.distributor
        />
        <!-- <filter-brand class=" mr-2 mt-2" v-model:value="brand" /> -->
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:distributor="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
          v-can:hide.distributor
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />

        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- <filter-segment
          class="mr-2 mt-2"
          :mode="null"
          style="width: 300px;"
          placeholder="Pilih Tipe Customer"
          v-model:value="segment"
        /> -->
        <!-- <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledStartDate"
          v-model:value="start_date"
          placeholder=" Tanggal Awal"
          format="DD MMMM YYYY"
        /> -->
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          @click="fetchXlsx"
          :loading="isDownloading"
          class="mr-3"
          title="Download Excel"
          type="primary"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
      <!-- <div class="download-excel col-lg-6 col-md-12 text-right">
        <a-button
          v-if="Math.floor((dataExport.length / totalCount) * 100) >= 100"
          title="Download Excel"
          type="primary"
          @click="downloadReport"
        >
          <i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i> Download Data
        </a-button>
        <a-button
          v-else
          title="Download Excel"
          type="primary"
          :loading="isDownloading"
          :disabled="data.length === 0 || exportDataValidation === false ? true : false"
          @click="processDownloading"
        >
          <i class="fa fa-file-excel-o ml-2 mr-2" aria-hidden="true"></i>
          {{
            exportDataValidation === true
              ? ''
              : Math.floor((dataExport.length / totalCount) * 100) + ` %`
          }}
        </a-button>
      </div> -->
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <md-table
                :columns="columns"
                :data-source="data"
                size="small"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #region="{ text }">
                  <span>{{ text.region_name }}</span>
                </template>
                <template #customer_code="{ text }">
                  <span>{{ text.code }}</span>
                </template>
                <template #customer_name="{ text }">
                  <span>{{ text.nama_toko }}</span>
                </template>
                <template #customer_address="{ text }">
                  <span>{{ text.address }}</span>
                </template>
                <template #area="{ text }">
                  <span>{{ text.area_name }}</span>
                </template>
                <template #kota="{ text }">
                  <span>{{ text.kabupaten }}</span>
                </template>
                <template #provinsi="{ text }">
                  <span>{{ text.provinsi }}</span>
                </template>
                <template #user_ssm="{ text }">
                  <span>{{ text.ssm != null ? text.ssm : '-' }}</span>
                </template>
                <template #user_sm="{ text }">
                  <span>{{ text.sm != null ? text.sm : '-' }}</span>
                </template>
                <template #user_am="{ text }">
                  <span>{{ text.am != null ? text.am : '-' }}</span>
                </template>
                <!-- <template #code_dist="{ text }">
                  <span>{{ text.dist_code_map_list.replace(/([^\w ]|_)/g, '') }}</span>
                </template> -->
                <template #name_dist="{ text }">
                  <span>{{
                    text.dist_map_list.replace(/[`~!@#$%^&*()_|+\=?;:'",.|\{\}\[\]\\\/]/gi, '')
                  }}</span>
                </template>
                <template #avarage="{ text }">
                  <span>{{ text.avg_volume != null ? text.avg_volume : '-' }}</span>
                </template>
                <!-- <template #code_non_mapping="{ text }">
                  <span>{{ text.dist_vol_list_non_mapping }}</span>
                </template> -->
                <template #dist_non_mapping="{ text }">
                  <span>{{
                    text.dist_vol_list_non_mapping.replace(
                      /[`~!@#$%^&*()_|+\=?;:'",.|\{\}\[\]\\\/]/gi,
                      '',
                    )
                  }}</span>
                </template>
                <template #volume="{ text }">
                  <span>{{ text.volume_qty }}</span>
                </template>
                <template #action="{ text }">
                  <div class="d-flex align-items-center">

                    <a-tooltip title="Download Detail Transaksi Average Volume Toko 3 Bulan">
                      <a-button
                        :loading="isDownloading3Month"
                        @click="fetchXlsx3Month(text.code)"
                        class="button mx-1  btn-sm"
                        size="small"
                        :disabled="text.avg_volume === null ? true : false"
                      >
                        <i
                          class="fa fa-file-excel-o"
                          aria-hidden="true"
                          v-if="text.progress_download_3_month === false"
                        ></i>
                        <span v-else>Downloading ...</span>
                      </a-button>
                    </a-tooltip>
                    <a-tooltip title="Download Detail Transaksi Average Volume Toko 2 Bulan">
                      <a-button
                        :loading="isDownloading2Month"
                        @click="fetchXlsx2Month(text.code)"
                        class="button mx-1 btn-sm"
                        size="small"
                        :disabled="text.volume_qty === null ? true : false"
                      >
                        <i
                          class="fa fa-file-excel-o"
                          aria-hidden="true"
                          v-if="text.progress_download_2_month === false"
                        ></i>
                        <span v-else>Downloading ...</span>
                      </a-button>
                    </a-tooltip>
                  </div>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterSegment from '@/components/filter/FilterSegment'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Region',
    slots: { customRender: 'region' },
  },
  {
    title: 'ID Toko',
    slots: { customRender: 'customer_code' },
  },
  {
    title: 'Nama Toko',
    slots: { customRender: 'customer_name' },
  },
  {
    title: 'Alamat',
    slots: { customRender: 'customer_address' },
  },
  {
    title: 'Area',
    slots: { customRender: 'area' },
  },
  {
    title: 'Kota',
    slots: { customRender: 'kota' },
  },
  {
    title: 'Provinsi',
    slots: { customRender: 'provinsi' },
  },

  {
    title: 'SSM',
    slots: { customRender: 'user_ssm' },
  },
  {
    title: 'ASM',
    slots: { customRender: 'user_sm' },
  },
  {
    title: 'TSO',
    slots: { customRender: 'user_am' },
  },

  // {
  //   title: 'Kode Distributor',
  //   slots: { customRender: 'code_dist' },
  // },
  {
    title: 'Distributor Mapping',
    slots: { customRender: 'name_dist' },
  },
  {
    title: 'Average Volume Toko (3 Bulan Sebelum Disuggest Toko Lelang)',
    slots: { customRender: 'avarage' },
  },
  // {
  //   title: 'Kode Distributor',
  //   slots: { customRender: 'code_non_mapping' },
  // },
  {
    title: 'Distributor Non Mapping',
    slots: { customRender: 'dist_non_mapping' },
  },
  {
    title: 'Average Volume Toko (2 Bulan Terakhir yang Disupply oleh Distributor Non Mapping)',
    slots: { customRender: 'volume' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

const acolumnsMonthValue = [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Customer',
    dataIndex: 'customer_code',
  },
  {
    title: 'Nama Customer',
    dataIndex: 'customer_name',
  },
  {
    title: 'Alamat Customer',
    dataIndex: 'customer_address',
  },
  {
    title: 'Regional Customer',
    dataIndex: 'customer_regional',
  },
  {
    title: 'Kabupaten Customer',
    dataIndex: 'customer_kabupaten',
  },
  {
    title: 'Provinsi Customer',
    dataIndex: 'customer_propinsi',
  },
  {
    title: 'Area Customer',
    dataIndex: 'customer_area',
  },
  {
    title: 'Tipe Cluster',
    dataIndex: 'cluster_name',
  },
  {
    title: 'Brand',
    dataIndex: 'brand_name',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'UOM 1',
    dataIndex: 'uom1',
  },
  {
    title: 'UOM 2',
    dataIndex: 'uom2',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
  },
  {
    title: 'Kode Transaksi',
    dataIndex: 'transaksi_code',
  },
  {
    title: 'Kode Gudang',
    dataIndex: 'warehouse_code',
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'warehouse_name',
  },
  {
    title: 'Regional',
    dataIndex: 'regional_name',
  },
  {
    title: 'Kabupaten Gudang',
    dataIndex: 'warehouse_kabupaten',
  },
  {
    title: 'Provinsi Gudang',
    dataIndex: 'warehouse_propinsi',
  },
  {
    title: 'Area Gudang',
    dataIndex: 'warehouse_area',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor_name',
  },
  {
    title: 'SSM',
    dataIndex: 'user_ssm',
  },
  {
    title: 'ASM',
    dataIndex: 'user_sm',
  },
  {
    title: 'TSO',
    dataIndex: 'user_am',
  },
  {
    title: 'ERP',
    dataIndex: 'erp_name',
  },
]

import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    // FilterBrand,
    // FilterSegment,
  },
  setup() {
    const API_URL = '/api/report-lelang/lintas-mapping'
    const API_URL_DETAIL_2MONTH = 'api/report-lelang/detail-lintas-mapping-volume2bulan/toko'
    const API_URL_DETAIL_3MONTH = '/api/report-lelang/detail-lintas-mapping-volume3bulan/toko'
    const route = useRoute()
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const perPageXlsx = ref(10)
    const pageCountXlsx = ref(0)
    const totalCountXlsx = ref(0)
    const metaXlsx = ref({})
    const pageXlsx = ref(0)

    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const columnsMonthValue = ref([])
    const data = ref([])
    const dataExport = ref([])
    const totalData = ref(0)
    const offset = ref(0)
    const current1 = ref(1)
    const q = ref('')
    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const product = ref([])
    const brand = ref([])
    const distributor = ref([])
    const segment = ref([])
    const { isDistributor, isHelpdesk, isAm, vendor_id, regions, areas } = useUserInfo()
    const area = ref([])
    const errorMessage = ref(null)
    const exportDataValidation = ref(true)
    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()
    const params = ref({})
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
      isDownloading2Month: false,
      isDownloading3Month: false,
    })

    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }
    if (isHelpdesk.value) {
      regions.value.forEach(item => {
        region.value.push(item.id)
      })
    }
    if (isAm.value) {
      areas.value.forEach(item => {
        area.value.push(item.id)
      })
    }

    start_date.value = moment(new Date()).startOf('month')
    end_date.value = moment(new Date())
    columns.value = acolumns
    columnsMonthValue.value = acolumnsMonthValue

    // Function

    const fetchData = async () => {
      
      if (q.value) {
        page.value = 1
      }

      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        brand: brand.value,
        // start_date: startDate.value,
        // end_date: endDate.value,
        distributor: distributor.value,
        type: segment.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      await apiClient
        .get(API_URL, {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
            item['progress_download_2_month'] = false
            item['progress_download_3_month'] = false
          })

          // Data pada table
          data.value = items

          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchDataXlsx = async () => {
      perPageXlsx.value = perPage.value
      const _params = {
        page: pageXlsx.value,
        'per-page': perPageXlsx.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        brand: brand.value,
        start_date: startDate.value,
        distributor: distributor.value,
        type: segment.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isDownloading = true
      await apiClient
        .get(API_URL, {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
          })

          // Data yang akan di export
          items.forEach(element => {
            dataExport.value.push(element)
          })

          if (dataExport.value.length < totalCount.value) {
            exportDataValidation.value = false
          } else {
            exportDataValidation.value = true
          }

          metaXlsx.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCountXlsx.value = metaXlsx.value.pageCount
          totalCountXlsx.value = metaXlsx.value.totalCount
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get(API_URL, {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${(route.meta.title_db ? route.meta.title_db : route.meta.title)
              .toLowerCase()
              .replaceAll(' ', '-')}_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const fetchXlsx2Month = code => {
      data.value.find(item => item.code === code).progress_download_2_month = true
      apiClient
        .get(API_URL_DETAIL_2MONTH, {
          params: {
            ...params.value,
            customer_code: code,
            _export: 'xls',
            _columns: columnsMonthValue.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },

          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          console.log('response', response)
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Detail ${(route.meta.title_db ? route.meta.title_db : route.meta.title)
              .toLowerCase()
              .replaceAll(' ', '-')}_2_Bulan_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          data.value.find(item => item.code === code).progress_download_2_month = false
        })
    }

    const fetchXlsx3Month = code => {
      data.value.find(item => item.code === code).progress_download_3_month = true
      apiClient
        .get(API_URL_DETAIL_3MONTH, {
          params: {
            ...params.value,
            customer_code: code,
            _export: 'xls',
            _columns: columnsMonthValue.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Detail ${(route.meta.title_db ? route.meta.title_db : route.meta.title)
              .toLowerCase()
              .replaceAll(' ', '-')}_3_Bulan_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          data.value.find(item => item.code === code).progress_download_3_month = false
        })
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    return {
      q,
      searchText,
      searchInput,
      data,
      dataExport,
      totalData,
      columns,
      columnsMonthValue,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      perPageXlsx,
      totalCountXlsx,
      pageCountXlsx,
      pageXlsx,
      metaXlsx,
      handleTableChange,
      startRow,
      region,
      provinsi,
      kabupaten,
      product,
      area,
      brand,
      segment,
      onSelectChange,
      state,
      fetchXlsx,
      fetchXlsx2Month,
      fetchXlsx3Month,
      fetchData,
      fetchDataXlsx,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      distributor,
      offset,
      exportDataValidation,
      // innerColumns,
    }
  },

  methods: {
    async processDownloading() {
      if (this.totalCount < 100) {
        // this.perPage = 10

        if (this.totalCount <= 10) {
          await this.fetchDataXlsx()
          this.offset = this.totalCount
        } else {
          for (this.offset = 0; this.offset < this.pageCount; this.offset += 10) {
            this.pageXlsx++
            await this.fetchDataXlsx()
          }
        }
      } else {
        for (this.offset = 0; this.offset < this.pageCount; this.offset++) {
          // if (this.totalCount - this.offset <= 100) {
          //   this.offset++
          //   this.page++
          // } else {
          //   this.offset += 100
          // }

          this.pageXlsx++

          await this.fetchDataXlsx()
        }
      }
      // this.fetchData()
    },

    // async downloadReport() {
    //   const header = [
    //     'TANGGAL TRANSAKSI',
    //     'ID TOKO',
    //     'NAMA TOKO',
    //     'ALAMAT TOKO',
    //     'KABUPATEN',
    //     'PROVINSI',
    //     'REGIONAL',
    //     'AREA',
    //     'BRAND',
    //     'KODE PRODUK',
    //     'NAMA PRODUK',
    //     'HARGA',
    //     'ZAK QUANTITY',
    //     'UOM 1',
    //     'TON QUANTITY',
    //     'UOM 2',
    //     'NO TRANSAKSI',
    //     'KODE DISTRIBUTOR',
    //     'NAMA DISTRIBUTOR',
    //     'KODE GUDANG',
    //     'NAMA GUDANG',
    //     'REGIONAL GUDANG',
    //     'PROVINSI GUDANG',
    //     'AREA GUDANG',
    //     'KABUPATEN GUDANG',
    //     'TIPE CUSTOMER',
    //     'CLUSTER',
    //     'SSM',
    //     'ASM',
    //     'TSO',
    //     'CREATED AT',
    //   ]
    //   const filterVal = [
    //     'tanggal',
    //     'customer_code',
    //     'customer_name',
    //     'customer_address',
    //     'customer_kabupaten',
    //     'customer_propinsi',
    //     'customer_regional',
    //     'customer_area',
    //     'brand_name',
    //     'product_code',
    //     'product_name',
    //     'price',
    //     'quantity_zak',
    //     'uom1',
    //     'quantity_ton',
    //     'uom2',
    //     'transaksi_code',
    //     'distributor_code',
    //     'distributor_name',
    //     'warehouse_code',
    //     'warehouse_name',
    //     'regional_name',
    //     'warehouse_propinsi',
    //     'warehouse_area',
    //     'warehouse_kabupaten',
    //     'type_name',
    //     'cluster_name',
    //     'user_ssm',
    //     'user_sm',
    //     'user_am',
    //     'created_at_dist',
    //   ]
    //   this.exportToExcel(
    //     header,
    //     filterVal,
    //     this.dataExport,
    //     'Data Laporan Detail Transaksi Toko' + ` - ` + moment(new Date()).format('DDMMYY_HHmm'),
    //   )
    // },

    // exportToExcel(header, filterVal, list, filename) {
    //   import('@/vendor/Export2Excel').then(excel => {
    //     const data = this.formatJson(filterVal, list)
    //     excel.export_json_to_excel({
    //       header,
    //       data,
    //       filename,
    //       autoWidth: this.autoWidth,
    //       bookType: this.bookType,
    //     })
    //   })

    //   this.dataExport = []
    //   this.pageXlsx = 0
    //   this.offset = 0
    //   this.exportDataValidation = true
    // },
    // formatJson(filterVal, jsonData) {
    //   return jsonData.map(v =>
    //     filterVal.map(j => {
    //       if (j === 'timestamp') {
    //         return parseTime(v[j])
    //       } else {
    //         return v[j]
    //       }
    //     }),
    //   )
    // },
  },
})
</script>
